<template>
	<div class="box">
		<div class="row">
			<DateRangeV2
				:start.sync="duration.start"
				:end.sync="duration.end"
				@submit="setDuration()"
				class="col-9 mr-4"
			/>
			<router-link class="btn btn-primary mb-2 ml-4 align-self-end col-2" style="border-radius: 4px;" :to="{ name: 'acteAjout', params: {horse_id: horse_id, type_preselect:'SUIVI_TEMPERATURE', from: 'HorseSuiviTemperature'}}">{{ $t('global.ajouter_acte') }} <font-awesome-icon :icon="['fal', 'plus']" /></router-link>
		</div>
		<template v-if="ready" class="mt-4">
			<h4>{{ $t('temperature.graphique') }}</h4>
			<apexchart type="line" height="350" :options.sync="chartOptions" :series.sync="series_temperature"></apexchart>
		</template>
		<LoadingSpinner v-else />
		<div class="row">
			<div class="col-12">
        		<h3>{{ $t('temperature.liste') }}</h3>
			</div>
			<div v-if="ready" class="col-12">

				<table class="table table-hover table-sm mb-0">
					<thead>
						<tr>
							<th>{{ $t('temperature.date_only') }}</th>
							<th>{{ $t('temperature.time_only') }}</th>
							<th>{{ $t('temperature.temperature') }}</th>
							<th>{{ $t('temperature.modification') }}</th>
						</tr>
					</thead>

					<tbody>
					<tr v-for="temperature in temperatures" :key="temperature.temperature_id">
						<td>{{ temperature.date }}</td>
						<td>{{temperature.temperature_time}}</td>
						<td> {{temperature.temperature_temperature}}</td>
						<td ><router-link class="btn btn-primary" :to="{ name: 'AddHorseTemperature', params: {horse_id: horse_id, temperature_id: temperature.temperature_id }}">{{ $t('global.modifier') }} <font-awesome-icon :icon="['fal', 'angle-right']" /></router-link></td>
					</tr>
					</tbody>
				</table>
			</div>
			<LoadingSpinner v-else />
		</div>
	</div>
</template>

<script type="text/javascript">
	import VueApexCharts from 'vue-apexcharts'
	import Temperature from "@/mixins/Temperature.js"

	export default {
		name: 'HorseTemperature',
		props: ['horse_id'],
		mixins: [Temperature],
		data () {
			return {
				temperatures: [],
				ready: false,
				series_temperature: [{name:'temperature', data:[]}],
				chartOptions: {
					chart: {
						height: 350,
						type: 'line'
					},
					dataLabels: {
						enabled: false
					},
					stroke: {
						curve: 'straight'
					},
					xaxis: {
						type: 'string',
						categories: [],
						convertedCatToNumeric: false,
						labels: {
							rotate: 0,
							hideOverlappingLabels: true,
							style: {
								colors: '#ACB2BC'
							}
						}
					},
					yaxis: {
						labels: {
							style: {
								colors: '#ACB2BC'
							}
						},
						min:34,
						max:41
					},
					legend: {
						labels: {
							colors: '#ACB2BC'
						}
					},
					colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63'],
					annotations: {
						yaxis: [
							{
								y: 37.5,
								y2: 38.5,
								fillColor: '#B3F7CA',
								label: {
									text: this.getTrad('temperature.ideal')
								}
							},
						]
					}
				},
				duration: {start: new Date(new Date().setDate(new Date().getDate() - 7)), end: new Date()},
				
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.ready = false
                const id = this.$sync.replaceWithReplicated('horse', this.horse_id)
				
				let formatted_start = this.duration.start.getFullYear() + "-" + (this.duration.start.getMonth() + 1) + "-" + this.duration.start.getDate()
				let formatted_end = this.duration.end.getFullYear() + "-" + (this.duration.end.getMonth() + 1) + "-" + this.duration.end.getDate()

				this.temperatures = await this.getTemperatureOnDuration(this.horse_id, formatted_start, formatted_end)

				this.temperatures.forEach(temperature => {
					temperature.date = Date.parseTz(temperature.temperature_date).toLocaleDateString()
				})
				this.ready = true
				this.loadGraph()
			},

			async loadGraph() {

				this.ready = false
				this.series_temperature[0].data = []
				this.chartOptions.xaxis.categories = []

				let formatted_start = this.duration.start.getFullYear() + "-" + (this.duration.start.getMonth() + 1) + "-" + this.duration.start.getDate()
				let formatted_end = this.duration.end.getFullYear() + "-" + (this.duration.end.getMonth() + 1) + "-" + this.duration.end.getDate()

				const data = await this.getTemperatureOnDuration(this.horse_id, formatted_start, formatted_end)
				if (data.length == 0){
					this.ready = true
					return
				}

				let tmp = {
					temperature_id: 0,
					temperature_time: "00:00:00",
					temperature_date: "0000-00-00 00:00:00",
					temperature_temperature: 0
				}
				let lastOneIsAfternoon = false

				for (const temperature of data){
					if (temperature.temperature_date == tmp.temperature_date){
						if(lastOneIsAfternoon){
							continue
						} else if (parseInt(temperature.temperature_time.substr(0,2)) >= 12){
							this.series_temperature[0].data[this.series_temperature[0].data.length-1] = temperature.temperature_temperature
							lastOneIsAfternoon = true
						}
					} else {
						lastOneIsAfternoon = false
						this.series_temperature[0].data.push(temperature.temperature_temperature)
						this.chartOptions.xaxis.categories.push(temperature.temperature_date.substr(0,10) + " "+ this.getTrad('temperature.matin'))
						this.series_temperature[0].data.push(temperature.temperature_temperature)
						this.chartOptions.xaxis.categories.push(temperature.temperature_date.substr(0,10) + " " + this.getTrad('temperature.apres_midi'))
						if (parseInt(temperature.temperature_time.substr(0,2)) > 12){
							lastOneIsAfternoon = true
						}
					}
					tmp = temperature
				}
				this.ready = true
			},

			setDuration() {
				this.init_component()
			}
		},

		watch: {
			'$route.name'() {
				this.temperatures = []
				this.init_component()
			}
		},

		components: {
			apexchart: VueApexCharts,
            DateRangeV2: () => import('GroomyRoot/components/Inputs/DateRangeV2'),
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35') 
		}
	}

</script>